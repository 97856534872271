import React, { useEffect } from 'react'
import { GlobeAltIcon } from '@heroicons/react/outline'
import { Button } from '@thepokencompany/ui'

import { Modal } from '../base/Modal'

import { chainsName, useWalletContext } from '../../utils/wallet'
import { useModalContext } from '../../utils/modal'

export default function ChangeWalletConnectChain({ desiredChain, onClick, onUpdate = () => {} }) {
  const { currentChain } = useWalletContext()
  const { closeModal } = useModalContext()

  useEffect(() => {
    if (desiredChain === currentChain) {
      closeModal()
      onUpdate()
    }
  }, [currentChain])

  return (
    <>
      <Modal.Header>{`Change Wallet Connect Chain`}</Modal.Header>
      <Modal.Separator />
      <div className='flex flex-col max-h-screen'>
        <div className='flex flex-col justify-center items-center'>
          <GlobeAltIcon className='h-20 w-20' />

          <h2 className='flex-grow text-white text-center my-4'>Your current Wallet Connect is on the wrong network</h2>

          <p>
            Please, change to <span className='text-primary text'>{chainsName[desiredChain]}</span> by creating a new
            session
          </p>

          <Button className='mt-4 ml-auto w-full' outlined onClick={onClick}>
            Create new session
          </Button>
        </div>
      </div>
    </>
  )
}
