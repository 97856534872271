import { useRef, useEffect } from 'react'

export function truncateWallet(wallet: string, long?: number) {
  if (!wallet) return ''

  return `${wallet.substring(0, long || 5)}...${wallet.substring(wallet.length - (long - 1 || 4), wallet.length)}`
}

export const isBrowser = typeof window !== 'undefined'

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
