import React from 'react'

import logo from '../../../public/images/logo.svg'

import { Modal } from '../base/Modal'
import { CHAIN_SCAN_HOST } from '../../utils/constants'
import { useWalletContext } from '../../utils/wallet'

export default function ProcessingTransaction() {
  const { transactionInfos } = useWalletContext()

  if (!transactionInfos) return null

  return (
    <>
      <Modal.Header>{`Transaction in progress`}</Modal.Header>
      <Modal.Separator />

      <div className='flex flex-col max-h-screen text-center'>
        <div className='flex flex-col justify-center items-center'>
          <img src={'/images/logo.svg'} alt="metabella" className='h-20' />
        </div>
        <div className='mt-2 w-full'>
          <p>
            A transaction to <span className='text-primary text'>{transactionInfos?.type}</span> has been triggered
            in your wallet.
          </p>
          {transactionInfos?.hash ? (
            <p className='mt-2'>
              Transaction in progress: view it on{' '}
              <a
                target='_blank'
                href={`${CHAIN_SCAN_HOST[transactionInfos?.chain || 'ERC']}/tx/${transactionInfos?.hash}`}
                rel='noreferrer'
                className='underline cursor-pointer'
              >
                {CHAIN_SCAN_HOST[transactionInfos?.chain || 'ERC'].split('//')[1]}
              </a>
            </p>
          ) : (
            <p className='mt-2'>
              Please <span className='text-primary text'>verify your wallet</span> in order to continue.
            </p>
          )}
        </div>
      </div>
    </>
  )
}
