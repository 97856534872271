export enum Chain {
  ERC = 'ERC',
  BSC = 'BSC'
}

export interface ChainInfos {
  [Chain.ERC]: number
  [Chain.BSC]: number
  total: number
}

interface Unlock {
  chain: Chain
  time: number
}
export interface StackInfos {
  [Chain.ERC]: number
  [Chain.BSC]: number
  total: number
  unlock: Unlock | null
}

export interface ChainInfosBool {
  [Chain.ERC]: boolean
  [Chain.BSC]: boolean
}

export interface IcoInfos {
  chain: Chain
  amount: number
  amountClaimed: number
  monthsClaimed: number
  availableAmount: number
  startTime: number
}

export interface WalletInfos {
  pknBalance: ChainInfos
  pknAllowance: ChainInfos
  pknPooled: ChainInfos
  pknStacked: StackInfos
  pknStacked12: StackInfos
  pknStacked24: StackInfos
  isApprovedForAll: ChainInfosBool
  icoAllocation: IcoInfos
}

export enum Providers {
  injected = 'MetaMask',
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect'
}
