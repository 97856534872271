import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import TagManager from 'react-gtm-module'

import { ModalProvider } from '../utils/modal'
import { WalletProvider } from '../utils/wallet'

import '../styles/globals.css'

const GTM_ID = 'GTM-PFN7RB6'

const GTM_PARAMS = {
  gtmId: GTM_ID,
  auth: process.env.NEXT_PUBLIC_GTM_AUTH,
  preview: process.env.NEXT_PUBLIC_GTM_PREVIEW
}


function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize(GTM_PARAMS)
  }, [])

  return (
    <ModalProvider>
      <WalletProvider>
        <Component {...pageProps} />
      </WalletProvider>
    </ModalProvider>
  )
  
}

export default MyApp
