import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import { ethers, utils } from 'ethers'
import * as Sentry from '@sentry/nextjs'
import { toast } from 'react-toastify'
import Web3 from "web3";

import ProcessingTransaction from '../components/ModalWallet/ProcessingTransaction'
import ChooseWallet from '../components/ModalWallet/ChooseWallet'
import ChangeMetamaskChain from '../components/ModalWallet/ChangeMetamaskChain'
import ChangeWalletConnectChain from '../components/ModalWallet/ChangeWalletConnectChain'

import metabellaABI from './metabella.abi'

import { usePrevious } from './'
import { CONTRACT_ADDRESS, IS_TEST_NETWORK } from './constants'
import { useModalContext } from './modal'

import { Providers } from './types'

declare global {
  interface Window {
    ethereum: any
  }
}

interface WalletProviderOptions {
  chainId?: number
  reset?: boolean
}

const networks = {
  1: 'mainnet',
  4: 'rinkeby'
}

export const chainsName = {
  1: 'Ethereum Mainnet',
  4: 'Rinkeby Test Net'
}

export const chains = {
  1: {
    chainId: '0x1',
    chainSymbol: 'ERC'
  },
  4: {
    chainId: '0x4',
    chainSymbol: 'ERC'
  }
}

export const chainsMapping = {
  ERC: IS_TEST_NETWORK ? 4 : 1
}

export type Chain = 'ERC' | 'BSC'

const desiredChain = IS_TEST_NETWORK ? 4 : 1

const WalletContext = createContext(null)
export const useWalletContext = () => useContext(WalletContext)

export function WalletProvider(props) {
  const { openModal, closeModal, modalProps } = useModalContext()

  const [client, setClient] = useState(null)
  const [provider, setProvider] = useState(null)
  const [cachedProvider, setCachedProvider] = useState(null)

  const [currentAccount, setCurrentAccount] = useState('')
  const [currentChain, setCurrentChain] = useState(null)
  
  const [totalSupply, setTotalSupply] = useState(0)
  const [maxSupply, setMaxSupply] = useState(0)
  const [metabellaPrice, setMetabellePrice] = useState(0)
  const [maxMintPerTx, setMaxMintPerTx] = useState(10000)
  const [ownedNfts, setOwnedNfts] = useState(0)

  const [transactionInfos, setTransactionInfos] = useState(null)
  const prevTransactionInfos: any = usePrevious(transactionInfos)

  const [isWhitelisted, setIsWhitelisted] = useState(false)
  const [isPreSale, setIsPreSale] = useState(false)
  const [isPublicSale, setIsPublicSale] = useState(false)

  useEffect(() => {
    async function init() {
      await initWeb3Modal()
    }
    init()
  }, [])

  useEffect(() => {
    if(!currentAccount) return
    (async () => {
      const provider = new ethers.providers.JsonRpcProvider(
        IS_TEST_NETWORK ? 'https://thrumming-frosty-sky.rinkeby.quiknode.pro/' : 'https://old-restless-star.quiknode.pro'
      )
  
      const metabellaContract = new ethers.Contract(CONTRACT_ADDRESS, metabellaABI, provider)
      const balance = await metabellaContract.balanceOf(currentAccount)
      currentAccount && setIsWhitelisted(await metabellaContract.isWhitelisted(currentAccount))

      setOwnedNfts(parseInt(ethers.utils.formatUnits(balance,0), 10))
    })()
  }, [currentAccount])

  useEffect(() => {
    callContractData()
  }, [])

  useEffect(() => {
    if (!transactionInfos) return closeModal()
    if (transactionInfos.hash || (prevTransactionInfos && prevTransactionInfos.type !== transactionInfos.type)) {
      return
    }

    return openModal({
      content: <ProcessingTransaction />
    })
  }, [transactionInfos])

  async function callContractData() {
    const provider = new ethers.providers.JsonRpcProvider(
      IS_TEST_NETWORK ? 'https://thrumming-frosty-sky.rinkeby.quiknode.pro/' : 'https://old-restless-star.quiknode.pro'
    )

    const metabellaContract = new ethers.Contract(CONTRACT_ADDRESS, metabellaABI, provider)

    const isPreSale = await metabellaContract.isPresaleLive()
    const isPublicSale = await metabellaContract.isPublicsaleLive()
    setIsPreSale(isPreSale)
    setIsPublicSale(isPublicSale)

    const totalSupply = await metabellaContract.totalSupply()
    setTotalSupply(parseFloat(totalSupply))

    const maxSupply = await metabellaContract.MAX_SUPPLY()
    setMaxSupply(parseFloat(maxSupply))


    setMetabellePrice(parseFloat(utils.formatEther(await metabellaContract[isPublicSale ? 'PUBLICSALE_PRICE' :'PRESALE_PRICE' ]())))
    
    // setMaxMintPerTx(parseFloat(await metabellaContract.MAX_PRESALE_MINT()))
  }

  async function initWeb3Modal() {
    const chainId = desiredChain

    const web3Modal = new Web3Modal({
      network: networks[chainId],
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            chainId,
            network: networks[chainId],
            rpc: {
              1: 'https://old-restless-star.quiknode.pro',
              4: 'https://thrumming-frosty-sky.rinkeby.quiknode.pro/'
            },
            qrcodeModalOptions: {
              mobileLinks: ['trust'],
              desktopLinks: ['encrypted ink']
            }
          }
        }
      }
    })

    setClient(web3Modal)

    defineCachedProvider(web3Modal)

    return web3Modal
  }

  function accountsChanged(accounts: string[]) {
    const account: string = accounts[0]?.toLowerCase?.()
    setCurrentAccount(account)
  }

  function chainChanged(chainId) {
    setCurrentChain(parseInt(chainId, 16))
  }

  async function defineCachedProvider(client) {
    const currentCachedProvider = client?.providerController?.cachedProvider
    if (!currentCachedProvider) return setCachedProvider(null)

    setCachedProvider(
      currentCachedProvider === 'injected'
        ? client?.providerController.injectedProvider
        : client?.userOptions.find((option) => option.name.toLowerCase() === currentCachedProvider)
    )
  }

  async function initWeb3Provider(providerId, newClient?) {
    if (provider && cachedProvider?.id === providerId) {
      return new ethers.providers.Web3Provider(provider)
    }

    const web3Provider = newClient ? await newClient.connectTo(providerId) : await client.connectTo(providerId)

    if (providerId === 'injected') {
      web3Provider.on('accountsChanged', accountsChanged)
      web3Provider.on('chainChanged', chainChanged)
    }

    setProvider(web3Provider)

    await defineCachedProvider(client)

    const newProvider = new ethers.providers.Web3Provider(web3Provider)

    const accounts = await newProvider.listAccounts()
    const account: string = accounts[0]?.toLowerCase?.()
    setCurrentAccount(account)
    const chain = await newProvider.getNetwork()
    setCurrentChain(chain.chainId)

    return newProvider
  }

  async function resetCachedProvider() {
    await client.clearCachedProvider()
  }

  async function defineContext(newProvider) {
    if (provider && provider.name !== newProvider.name) await resetCachedProvider()

    if (newProvider.name === Providers.metamask) {
      newProvider.id = 'injected'
      return { client, provider: newProvider, web3Provider: await initWeb3Provider('injected') }
    }

    const isAllowedProvider = client.userOptions.find((option) => option.name === newProvider.name)
    if (!isAllowedProvider) {
      return toast.error(`Provider ${newProvider.name} is not available`)
    }

    const providerName = newProvider.name.toLowerCase()
    const availableChain = client.providerController.providerOptions[providerName].options.chainId
    const newClient = desiredChain && availableChain !== desiredChain ? await initWeb3Modal() : client

    return { provider: newProvider, client: newClient, web3Provider: await initWeb3Provider(providerName, newClient) }
  }

  async function chooseWalletProvider(options: WalletProviderOptions = {}): Promise<any> {
    try {
      const { reset } = options
      if (!reset && cachedProvider) {
        return {
          client,
          provider: cachedProvider,
          web3Provider: await initWeb3Provider(cachedProvider.id || cachedProvider.name.toLowerCase())
        }
      }

      return new Promise(async (resolve) => {
        openModal({
          content: (
            <ChooseWallet
              onChoose={async (provider) => {
                try {
                  if (provider.name === Providers.walletConnect) window?.localStorage?.removeItem('walletconnect')
                  const context = await defineContext(provider)
                  closeModal()
                  resolve(context)
                } catch (error) {
                  toast.error(`Fail to connect to wallet: ${error.message}`)
                  resolve(null)
                }
              }}
              providers={client.userOptions}
            />
          )
        })
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async function askMetamaskToChangeChain(web3Provider, chainId) {
    if (!chainId) throw new Error('Missing chain id')

    return new Promise(async (resolve, reject) => {
      openModal({
        content: (
          <ChangeMetamaskChain
            desiredChain={chainId}
            onUpdate={() => {
              resolve(null)
            }}
          />
        )
      })

      const chainConfig = chains[chainId]
      if (!chainConfig) {
        throw new Error(`Unknown chain ${chainId}`)
      }
      try {
        await web3Provider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainConfig.chainId }]
        })
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await web3Provider.provider.request({
              method: 'wallet_addEthereumChain',
              params: [chainConfig]
            })
          } catch (addError) {
            reject(addError)
          }
        }
      }
    })
  }

  async function resetWalletConnectSession() {
    try {
      window?.localStorage?.removeItem('walletconnect')
      const provider = client.userOptions.find((option) => option.name === Providers.walletConnect)
      return await defineContext(provider)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async function askWalletConnectToChangeChain(chainId) {
    return new Promise(async (resolve) => {
      openModal({
        content: (
          <ChangeWalletConnectChain
            desiredChain={chainId}
            onUpdate={() => {
              resolve(null)
            }}
            onClick={async () => {
              await resetWalletConnectSession()
            }}
          />
        )
      })
    })
  }

  async function getWalletProvider() {
    const { web3Provider, provider } = await chooseWalletProvider()

    const chain = await web3Provider.getNetwork()
    if (!chain || chain.chainId !== desiredChain) {
      if (provider.name === Providers.metamask) {
        await askMetamaskToChangeChain(web3Provider, desiredChain)
      }
      if (provider.name === Providers.walletConnect) {
        await askWalletConnectToChangeChain(desiredChain)
      }
    }

    const accounts = await web3Provider.listAccounts()
    const account = accounts[0]?.toLowerCase?.()

    setCurrentAccount(account)

    return initWeb3Provider(provider.id || provider.name?.toLowerCase())
  }

  async function getBalance(wallet): Promise<number> {
    if (!wallet) return 0

    const provider = new ethers.providers.JsonRpcProvider(
      IS_TEST_NETWORK ? 'https://rinkeby-light.eth.linkpool.io/' : 'https://old-restless-star.quiknode.pro'
    )

    const balance = await provider.getBalance(wallet)

    console.log(utils.formatEther(balance))
    return parseFloat(utils.formatEther(balance)) * 1000
  }

  async function mint(wantedMetabella) {
    try {
      const provider = await getWalletProvider()
      if (!provider) return

      setTransactionInfos({ chain: 'ERC', type: 'Mint Metabella' })
      
      const account: string = currentAccount?.toLocaleLowerCase()
      if (!account) {
        throw new Error('No wallet connected')
      }

      const price = metabellaPrice * wantedMetabella
      const ethPrice = utils.parseEther(price.toString())

      const balance = await getBalance(account)

      if (!balance || balance < price) {
        throw new Error('Insufficient ETH balance')
      }
      
      const metabellaContract = new ethers.Contract(CONTRACT_ADDRESS, metabellaABI, await provider.getSigner())
      // const gasAmount = await metabellaContract.estimateGas.mint(wantedMetabella, { from: account, value: ethPrice })

      const tx = await metabellaContract[isPreSale ? 'presaleMint' : 'mint'](wantedMetabella, { from: account, value: ethPrice })
      setTransactionInfos({ chain: 'ERC', type: 'Mint Metabella', hash: tx.hash })

      const receipt = await tx.wait()

      setTransactionInfos(null)

      if (!receipt.byzantium || !receipt.status || !receipt.confirmations) {
        throw new Error(`Something went wrong, please check transaction ${tx.hash}`)
      }

      return { tx, receipt }
    } catch (error) {
      setTransactionInfos(null)
      toast.error(`Fail to mint Metabella: ${error?.error?.message || error?.message || 'Unknown'}`)
      Sentry.captureException(error)
    }
  }

  const values = useMemo(
    () => ({
      isPreSale,
      isPublicSale,
      metabellaPrice,
      totalSupply,
      maxSupply,
      maxMintPerTx,
      client,
      cachedProvider,
      currentChain,
      currentAccount,
      transactionInfos,
      getWalletProvider,
      callContractData,
      mint,
      ownedNfts,
      isWhitelisted
    }),
    [
      client,
      currentChain,
      currentAccount,
      transactionInfos,
      metabellaPrice,
      totalSupply,
      maxMintPerTx,
      isPreSale,
      isPublicSale,
      maxSupply,
      getWalletProvider,
      callContractData,
      cachedProvider,
      ownedNfts,
      isWhitelisted
    ]
  )

  return <WalletContext.Provider {...props} value={values} />
}
