export const IS_PROD = process.env.NEXT_PUBLIC_ENV === 'production'
export const IS_TEST_NETWORK = !IS_PROD || process.env.NEXT_PUBLIC_TEST_NETWORK === 'true'

export const CONTRACT_ADDRESS = IS_TEST_NETWORK
  ? '0x07F5b78C635126798262a8D5fE71787A49402824'
  : '0xdf9e706DE8b7c504136A70141F72F2B09CBEEe9e'

export const BACK_END = process.env.NEXT_PUBLIC_BACK_END || 'http://localhost:5000'

export const ROOT_DOMAIN = process.env.NEXT_PUBLIC_ROOT_DOMAIN || 'http://localhost:5000'

export const CHAIN_SCAN_HOST = {
  ERC: IS_TEST_NETWORK ? 'https://rinkeby.etherscan.io' : 'https://etherscan.io'
}
